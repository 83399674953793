import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Loader from 'react-loader-spinner';
import { usePromiseTracker } from 'react-promise-tracker';
import { BrowserRouter as Router } from 'react-router-dom';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
	palette: {
		primary: {
			main: '#ec212f',
			light: '#ef5a24',
			dark: '#ed2a26',
			contrastText: '#fff'
		},
		secondary: {
			main: '#2B3D4F',
			contrastText: '#ba68c8'
		},
		alignItemsAndJustifyContent: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}
	}
});

const LoadingIndicator = props => {
	const { promiseInProgress } = usePromiseTracker();
	return (
		promiseInProgress && (
			<div
				style={{
					width: '100%',
					height: '100',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<Loader.ThreeDots color="#ec212f" height="100" width="100" />
			</div>
		)
	);
};

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<ThemeProvider theme={theme}>
				<App />
				<LoadingIndicator />
			</ThemeProvider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
