// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import BasicButtons from './Button';

// const theme = createTheme();

export default function SignInSide({ title, setPassword, setEmail, handleAction }) {
	const [passwordError, setPasswordError] = React.useState(null);
	const handleSubmit = event => {
		event.preventDefault();
	};

	return (
		// <ThemeProvider theme={theme}>
		<Grid container component="main" sx={{ height: '100vh' }}>
			<CssBaseline />
			{/* background */}
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: `url(${'QRFolder_main.jpg'})`,
					backgroundRepeat: 'no-repeat',
					backgroundColor: t => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				}}
			/>
			{/* menu lateral */}
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<Box
					sx={{
						my: 8,
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					{/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar> */}
					<Container maxWidth="sm">
						<Grid container alignItems="center" justifyContent="center">
							<img src="Logo_Pedimos_Icone.png" alt="Pedimos Logo" />
						</Grid>
						<p />
						<Grid container alignItems="center" justifyContent="center">
							<Typography component="h1" variant="h3" fontWeight="600">
								Bem vindo ao QR-Folder.
								<br />
							</Typography>
							<Typography component="h1" variant="h5">
								Entre com suas credenciais para acessar o sistema.
							</Typography>
						</Grid>
						<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="E-mail"
								name="email"
								autoComplete="email"
								autoFocus
								inputProps={{ style: { textTransform: 'lowercase' } }}
								onChange={e => setEmail(e.target.value)}
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Senha"
								type="password"
								id="password"
								autoComplete="current-password"
								onChange={e => setPassword(e.target.value)}
							/>
							<Grid container alignItems="center" justifyContent="center">
								<BasicButtons title={title} handleAction={handleAction} />
							</Grid>
						</Box>
					</Container>
				</Box>
			</Grid>
		</Grid>
		// </ThemeProvider>
	);
}
