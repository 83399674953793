import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#ec212f',
			light: '#ef5a24',
			dark: '#ed2a26',
			contrastText: '#fff'
		},
		secondary: {
			main: '#556372',
			contrastText: '#ba68c8'
		},
		action: {
			active: red[200],
			activeOpacity: 1,
			hover: red[100],
			hoverOpacity: 0.7,
			focus: red[600],
			focusOpacity: 1,
			selected: red[300],
			selectedOpacity: 1
		},
		alignItemsAndJustifyContent: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}
	}
});

export default function Footer() {
	return (
		<>
			<ThemeProvider theme={theme}>
				<Box
					// px={{ xs: 3, sm: 10 }}
					// py={{ xs: 5, sm: 10 }}
					bgcolor="#4B4E53"
					// backgroundImage='../loginBackground.jpg'
					color="white"
				>
					<Container maxWidth="lg">
						{/* <img src='../loginBackground.jpg' /> */}
						<Grid container alignItems="center" justifyContent="center">
							{/* <Grid item xs={12} sm={6}>
								<Box textAlign="center">
									<Typography component="h1" variant="h5" fontWeight="600">
										Pedimos Software de Gestão
									</Typography>
								</Box>
								<Box textAlign="center">
									<Typography component="h1" variant="body1">
										Desenvolvimento & Marketplace
									</Typography>
								</Box>
							</Grid> */}
							<Grid item xs={12} sm={6}>
								<Box textAlign="center">
									<Typography component="h1" variant="h5" fontWeight="600">
										QR-Folder
									</Typography>
								</Box>
								<Box textAlign="center">
									<Typography component="h1" variant="body1">
										Esse é um serviço da
									</Typography>
								</Box>
								<Box textAlign="center">
									<Typography component="h1" variant="body1">
										{' '}
										<Link
											href="https://www.pedimos.com.br/"
											// color='#fff'
											style={{ textDecoration: 'none' }}
										>
											<Typography color="#ec212f">pedimos.com.br</Typography>
										</Link>{' '}
										&copy; {new Date().getFullYear()}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</ThemeProvider>
		</>
	);
}
