import { Box, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { trackPromise } from 'react-promise-tracker';
import Footer from '../Components/Common/Footer';
import { db, doc, getDoc, getDownloadURL, ref, storage } from '../firebase-config';

pdfjs.GlobalWorkerOptions.workerSrc = '../pdf.worker.min.js';

export default function ViewPage() {
	const [image, setImage] = useState();
	const [extension, setExtension] = useState();
	const [userUid, setUserUid] = useState();
	const [numPages, setNumPages] = useState(null);
	const linkPath = window.location.pathname.replace('/qr/', '');

	const docRef = doc(db, 'cardapios', linkPath);

	const showFolder = useCallback(async () => {
		await setUserUid((await getDoc(docRef)).data().userUid);
		trackPromise(
			getDownloadURL(ref(storage, userUid + '/cardapio'))
				.then(url => {
					setImage(url);
				})
				.catch(error => {
					// Handle any errors
				})
		);

		setExtension((await getDoc(docRef)).data().extension);
		// console.log('extension: ', extension);
	}, [docRef, extension, userUid]);

	function onDocumentLoadSuccess({ numPages: nextNumPages }) {
		setNumPages(nextNumPages);
	}

	useEffect(() => {
		showFolder();
	});

	if (extension === 'pdf') {
		return (
			<>
				{/* <Grid item xs={12} sm={12} md={12}></Grid> */}
				<Grid item xs={12} sm={12} md={12}>
					<Grid
						container
						item
						alignItems="center"
						justifyContent="center"
						className="GridPdf"
						xs={12}
						sm={12}
						md={12}
					>
						<Document
							file={image}
							onLoadSuccess={onDocumentLoadSuccess}
							noData="Gerando visualização."
							loading="Gerando visualização."
						>
							{Array.from(new Array(numPages), (el, index) => (
								<Page
									key={`page_${index + 1}`}
									pageNumber={index + 1}
									width={document.getElementsByClassName('GridPdf')[0]?.clientWidth * 0.95 ?? 1080}
								/>
							))}
							{/* <Page pageNumber={1} /> */}
						</Document>
					</Grid>
				</Grid>
				<Footer />
			</>
		);
	}

	return (
		<>
			<Grid container alignItems="center" justifyContent="center">
				<Box
					component="img"
					sx={{
						maxWidth: { xs: 370, sm: 580, md: 890, lg: 1190, xl: 1520 }
					}}
					src={image}
				></Box>
			</Grid>
			<Footer />
		</>
	);
}
