import { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from './Components/Common/Form';
import FormChangePass from './Components/Common/FormChangePass';
import Home from './Components/Home';
import {
	auth,
	createUserWithEmailAndPassword,
	db,
	doc,
	EmailAuthProvider,
	reauthenticateWithCredential,
	setDoc,
	signInWithEmailAndPassword,
	updatePassword
} from './firebase-config';
import ViewPage from './qr/ViewPage';

function App() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [companyLinkName, setCompanyLinkName] = useState('');
	// const file = './no_image_uploaded.png'

	let navigate = useNavigate();
	// navigate('/login');
	const handleAction = async id => {
		// const authentication = getAuth();
		if (id === 1) {
			signInWithEmailAndPassword(auth, email, password)
				.then(response => {
					navigate('/home');
					sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
				})
				.catch(error => {
					if (error.code === 'auth/wrong-password') {
						toast.error('E-mail e/ou senha inválidos.');
					}
					if (error.code === 'auth/user-not-found') {
						toast.error('E-mail e/ou senha inválidos.');
					}
					if (error.code === 'auth/invalid-email') {
						toast.error('E-mail e/ou senha inválidos.');
					}
				});
		}
		if (id === 2) {
			createUserWithEmailAndPassword(auth, email, password)
				.then(response => {
					// console.log("response: ", response.user.uid);
					sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
					const companyKey = companyLinkName
						.toLowerCase()
						.trim()
						.replaceAll(' ', '-')
						.replaceAll('ç', 'c')
						.replaceAll('ã', 'a')
						.replaceAll('õ', 'o')
						.replaceAll('á', 'a')
						.replaceAll('é', 'e')
						.replaceAll('í', 'i')
						.replaceAll('ó', 'o')
						.replaceAll('ú', 'u');
					try {
						setDoc(
							doc(db, 'users', response.user.uid),
							{
								email: response.user.email,
								displayCompanyName: companyLinkName,
								companyKey
							},
							{ merge: true }
						);
						// console.log("response2: ", response.user.uid);
						setDoc(
							doc(db, 'cardapios', companyKey),
							{
								extension: '',
								displayCompanyName: companyLinkName,
								userUid: response.user.uid
							},
							{ merge: true }
						);
						// const storageRef = ref(storage, `${auth.currentUser.uid}/${'cardapio'}`);
						// const uploadTask = uploadBytesResumable(storageRef, file);
					} catch (error) {
						console.error(error);
					}

					navigate('/home');
				})
				.catch(error => {
					if (error.code === 'auth/email-already-in-use') {
						toast.error('E-mail já cadastrado.');
					}
					if (error.code === 'auth/invalid-email') {
						toast.error('Insira um e-mail válido.');
					}
					if (error.code === 'auth/invalid-password') {
						toast.error('Insira uma senha mais segura.');
					}
				});
		}
		if (id === 3) {
			const credential = EmailAuthProvider.credential(auth.currentUser.email, password);
			if (confirmPassword !== newPassword) {
				toast.error('Senha nova é diferente da confirmação.');
			} else {
				reauthenticateWithCredential(auth.currentUser, credential)
					.then(() => {
						// User re-authenticated.
						updatePassword(auth.currentUser, newPassword)
							.then(() => {
								// Update successful.
								toast.success('Senha atualizada.');
								navigate('/home');
							})
							.catch(error => {
								console.log('erro update senha: ', error);
							});
					})
					.catch(error => {
						if (error.code === 'auth/wrong-password') {
							toast.error('Senha atual incorreta.');
						} else console.log('erro de login ao atualizar senha: ', error);
					});
			}
		}
	};

	// useEffect(() => {

	//   //  navigate('/login')

	// }, [])

	return (
		<div className="App">
			<>
				<ToastContainer />
				{/* <ThemeProvider theme={theme}> */}
				<Routes>
					<Route
						path="/"
						element={
							<Form
								title="Login"
								setEmail={setEmail}
								setPassword={setPassword}
								handleAction={() => handleAction(1)}
							/>
						}
					/>
					<Route
						path="/login"
						element={
							<Form
								title="Entrar"
								setEmail={setEmail}
								setPassword={setPassword}
								handleAction={() => handleAction(1)}
							/>
						}
					/>
					{/* <Route
						path="/register"
						element={
							<FormCadastro
								title="Registrar conta"
								password={password}
								setEmail={setEmail}
								setPassword={setPassword}
								setCompanyLinkName={setCompanyLinkName}
								handleAction={() => handleAction(2)}
							/>
						}
					/> */}

					<Route
						path="/home"
						element={
							<Home
								title="Alterar a senha"
								setPassword={setPassword}
								setNewPassword={setNewPassword}
								setConfirmPassword={setConfirmPassword}
								handleAction={() => handleAction(3)}
							/>
						}
					/>

					<Route path="/senha" element={<FormChangePass />} />

					<Route path="/qr/:companyName" element={<ViewPage />} />
				</Routes>
				{/* </ThemeProvider> */}
			</>
		</div>
	);
}

export default App;
