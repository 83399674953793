import Button from '@mui/material/Button';
import * as React from 'react';

export default function BasicButtons({ title, handleAction, disabled }) {
	return (
		<Button variant="contained" onClick={handleAction} disabled={disabled}>
			{title}
		</Button>
	);
}
