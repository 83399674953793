import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PasswordIcon from '@mui/icons-material/Password';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
	AppBar,
	Box,
	Button,
	CssBaseline,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	TextField,
	Toolbar,
	Typography
} from '@mui/material';
import { alpha, createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { getAuth } from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db, doc, getDoc, getDownloadURL, ref, storage, updateDoc, uploadBytesResumable } from '../firebase-config';
import BasicButtons from './Common/Button';

const StyledMenu = styled(props => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right'
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		fontWeight: '600',
		// fontSize: 32,
		marginTop: theme.spacing(1),
		minWidth: 240,
		color: '#fff',
		background: '#405060',

		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0'
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 28,
				color: '#fff',
				marginRight: theme.spacing(1.5)
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity)
			}
		}
	}
}));

export default function Home({ title, setPassword, setNewPassword, setConfirmPassword, handleAction }) {
	const maxFileSize = 5 * 1024 * 1024;

	const [progress, setProgress] = useState(0);

	// image for upload
	const [image, setImage] = useState(null);
	const [pageMenu, setPageMenu] = useState(0);
	const [imageFromRemote, setImageFromRemote] = useState(null);

	const auth = getAuth();
	const user = auth.currentUser;
	// var storageRef = ref(storage, user.uid);
	const [fileName, setFileName] = useState('');
	const [companyKey, setCompanyKey] = useState('');

	const theme = createTheme({
		palette: {
			mode: 'light',
			primary: {
				main: '#ec212f',
				light: '#ef5a24',
				dark: '#ed2a26',
				contrastText: '#fff'
			},
			secondary: {
				main: '#2B3D4F',
				contrastText: '#ba68c8'
			},
			info: {
				main: '#ec212f',
				light: '#ef5a24',
				dark: '#ed2a26',
				contrastText: '#fff'
			},
			action: {
				active: '#fff'
			}
		},
		typography: {
			fontSize: 16
		}
	});

	const [anchorEl, setAnchorEl] = useState(null);
	const openNewMenu = Boolean(anchorEl);
	const handleClickMenu = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const recoveryCompanyKey = useCallback(async () => {
		const q = doc(db, 'users', user.uid);
		const querySnapshot = await getDoc(q);
		if (querySnapshot.exists()) {
			// console.log("querySnapshot: ", querySnapshot.data())
			setCompanyKey(querySnapshot.data().companyKey);
		} else {
			// console.log("nao encontrado query")
		}
	}, [user.uid]);

	function displayImage() {
		trackPromise(
			getDownloadURL(ref(storage, `${user.uid}/${'cardapio'}`))
				.then(async url => {
					const mimeExt = (await getDoc(doc(db, 'cardapios', companyKey))).data().extension;
					if (mimeExt === 'pdf') {
						setImageFromRemote('pdf_mini.png');
					} else {
						setImageFromRemote(url);
					}
				})
				.catch(error => {
					switch (error.code) {
						case 'storage/object-not-found':
							setImageFromRemote('no_image_uploaded.png');
							break;
						case 'storage/unauthorized':
							// User doesn't have permission to access the object
							break;
						case 'storage/canceled':
							// User canceled the upload
							break;
						case 'storage/unknown':
							// Unknown error occurred, inspect the server response
							break;
						default:
							// console.warn('Missing error code');
							break;
					}
				})
		);
	}
	displayImage();

	// const getCardapioCollection = useCallback(async companyName => {
	// 	const cardapioColRef = collection(db, 'cardapios');
	// 	const getDocuments = await getDocs(cardapioColRef);
	// 	let companyDoc;
	// 	getDocuments.forEach(doc => {
	// 		if (doc.id === companyName) {
	// 			companyDoc = doc.data();
	// 			return;
	// 		}
	// 	});
	// }, []);

	const uploadFiles = async file => {
		if (!file) {
			return;
		} else {
			const mime = file.name.split('.').pop();
			const storageRef = ref(storage, `${user.uid}/${'cardapio'}`);
			const imageFilter = file.type.split('/');
			if (imageFilter[0] == 'image' || file.type == 'application/pdf') {
				if (file.size <= maxFileSize) {
					const uploadTask = uploadBytesResumable(storageRef, file);
					await updateDoc(doc(db, 'cardapios', companyKey), {
						extension: mime,
						userUid: user.uid
					});
					uploadTask.on(
						'state_changed',
						snapshot => {
							const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
							setProgress(prog);
							toast.success('Arquivo enviado.', {
								toastId: 'file-sended'
							});
						},
						error => console.log(error),
						async () => {
							getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
								setImage(downloadURL);
							});
						}
					);
				} else toast.error('Arquivo deve ser menor que 5 MB.');
			} else toast.error('Arquivo deve ser uma imagem ou PDF.');
		}
	};

	const formHandler = e => {
		e.preventDefault();
		const file = e.target[0].files[0];
		uploadFiles(file);
		setFileName('');
	};

	const handleLogout = () => {
		sessionStorage.removeItem('Auth Token');
		navigate('/login');
	};

	let navigate = useNavigate();

	const seeFolder = () => {
		let companyName = '/qr/';
		companyName = companyName + companyKey;
		window.open(companyName, '_blank');
		// navigate(companyName);
	};

	recoveryCompanyKey();

	useEffect(() => {
		let authToken = sessionStorage.getItem('Auth Token');

		if (authToken) {
			navigate('/home');
		}

		if (!authToken) {
			navigate('/login');
		}
	}, [navigate]);

	// useEffect(() => {
	// 	getCardapioCollection(companyKey.replace('/', ''));
	// }, [getCardapioCollection, companyKey]);

	// material ui list:
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	function handleFileName(event) {
		setFileName(`${event.target.files[0].name}`);
	}

	return (
		<>
			{/* menu novo */}
			<ThemeProvider theme={theme}>
				<Grid container>
					<Grid item xs={3} sm={2} md={1}>
						<AppBar position="static">
							<Toolbar>
								<IconButton
									aria-label="more"
									id="long-button"
									aria-controls={open ? 'long-menu' : undefined}
									aria-expanded={open ? 'true' : undefined}
									aria-haspopup="true"
									onClick={handleClickMenu}
								>
									<MenuIcon />
								</IconButton>
							</Toolbar>
						</AppBar>
					</Grid>
					<Grid item textAlign="center" xs={9} sm={10} md={11}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								bgcolor: 'background.paper'
							}}
						>
							{/* <ThemeProvider theme={theme}> */}
							<AppBar position="static" color="primary">
								<Toolbar color="primary">
									<Typography align="center" variant="body1">
										Pedimos QR-Folder
									</Typography>
								</Toolbar>
							</AppBar>
							{/* </ThemeProvider> */}
						</Box>
					</Grid>
					<CssBaseline />
					<Grid item xs={12}>
						<p></p>
					</Grid>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							'aria-labelledby': 'demo-customized-button'
						}}
						anchorEl={anchorEl}
						open={openNewMenu}
						onClose={handleClose}
					>
						<MenuItem
							onClick={() => {
								setPageMenu(0);
								handleClose();
							}}
							disableRipple
						>
							<HomeIcon />
							Início
						</MenuItem>
						<MenuItem
							onClick={() => {
								seeFolder();
								handleClose();
							}}
							disableRipple
						>
							<VisibilityIcon />
							Pré-visualização
						</MenuItem>
						<MenuItem
							onClick={() => {
								setPageMenu(1);
								handleClose();
							}}
							disableRipple
						>
							<PasswordIcon />
							Alterar senha
						</MenuItem>
						<Divider sx={{ my: 0.5 }} />
						<MenuItem onClick={handleLogout} disableRipple>
							<LogoutIcon />
							Sair
						</MenuItem>
					</StyledMenu>

					{pageMenu === 0 && (
						<Grid item xs={12}>
							<Grid container alignItems="center" justifyContent="center">
								<Box
									component="img"
									sx={{
										maxWidth: { xs: 350, sm: 550, md: 850, lg: 1150, xl: 1500 }
									}}
									src={imageFromRemote ?? image}
								></Box>
							</Grid>
							<form onSubmit={formHandler}>
								<Grid item container alignItems="center" justifyContent="center">
									<Button variant="outlined" component="label" sx={{ p: 1 }}>
										Selecionar arquivo
										<input hidden type="file" accept="image/*,.pdf" onChange={handleFileName} />
									</Button>

									<Typography sx={{ p: 1 }}>{fileName}</Typography>

									<Button type="submit" variant="contained" endIcon={<SendIcon />} sx={{ p: 1 }}>
										Enviar
									</Button>
								</Grid>
							</form>
							{/* <Button onClick={handleLogout} variant="contained">
                            Sair
                        </Button> */}
						</Grid>
					)}
					{pageMenu === 1 && (
						<>
							<Typography></Typography>
							{/* <Grid item xs={3} sm={1} md={1} ></Grid> */}
							<Grid
								container
								item
								alignItems="center"
								justifyContent="center"
								columns={{ xs: 2, sm: 2, md: 2 }}
							>
								<Grid item container alignItems="center" justifyContent="center" xs={2}>
									<TextField
										margin="normal"
										required
										name="password"
										label="Senha atual"
										type="password"
										id="password"
										autoComplete="current-password"
										autoFocus
										onChange={e => setPassword(e.target.value)}
									/>
								</Grid>
								<Grid item container alignItems="center" justifyContent="center" xs={2}>
									<TextField
										margin="normal"
										required
										name="newPassword"
										label="Nova senha"
										type="password"
										id="newPassword"
										autoComplete="new-password"
										onChange={e => setNewPassword(e.target.value)}
									/>
								</Grid>
								<Grid item container alignItems="center" justifyContent="center" xs={2}>
									<TextField
										margin="normal"
										required
										name="confNewPassword"
										label="Confirmar nova senha"
										type="password"
										id="confNewPassword"
										autoComplete="new-password"
										onChange={e => setConfirmPassword(e.target.value)}
									/>
								</Grid>
								<Grid item container alignItems="center" justifyContent="center" xs={2}>
									<BasicButtons
										title={title}
										type="submit"
										variant="contained"
										handleAction={handleAction}
									></BasicButtons>
								</Grid>
							</Grid>
							{/* <Grid item xs={3} sm={1} md={1}></Grid> */}
						</>
					)}
				</Grid>
			</ThemeProvider>
		</>
	);
}
