import { initializeApp } from 'firebase/app';
import {
	createUserWithEmailAndPassword,
	EmailAuthProvider,
	getAuth,
	reauthenticateWithCredential,
	signInWithEmailAndPassword,
	updatePassword
} from 'firebase/auth';
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, getStorage, listAll, ref, uploadBytesResumable } from 'firebase/storage';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth();
const db = getFirestore(app);

export {
	auth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	reauthenticateWithCredential,
	updatePassword,
	EmailAuthProvider,
	storage,
	ref,
	getDownloadURL,
	uploadBytesResumable,
	listAll,
	query,
	where,
	app,
	db,
	collection,
	doc,
	getDocs,
	getDoc,
	setDoc,
	updateDoc
};
