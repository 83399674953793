import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { auth } from '../../firebase-config.js';
import Button from './Button';

const theme = createTheme();

const user = auth.currentUser;

// console.log("user: ", user)
export default function FormChangePass({ title, password, setPassword, setEmail, setCompanyLinkName, handleAction }) {
	const [passwordError, setPasswordError] = React.useState(null);
	const [confirmPassword, setConfirmPassword] = React.useState('');
	const handleSubmit = event => {
		event.preventDefault();
	};
	return (
		<>
			<ThemeProvider theme={theme}>
				<Grid container component="main" sx={{ height: '100vh' }}>
					<CssBaseline />
					<Grid item xs={12} sm={8} md={5} elevation={6} square>
						<Box
							sx={{
								my: 8,
								mx: 4,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}
						>
							{/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar> */}
							<Typography component="h1" variant="h5">
								Mudar senha
							</Typography>
							<Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Senha antiga"
									type="password"
									id="password"
									// autoComplete="new-password"
									// onChange={(e) => setPassword(e.target.value)}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Nova senha"
									type="password"
									id="password"
									autoComplete="new-password"
									onChange={e => setPassword(e.target.value)}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Confirme a senha"
									type="password"
									id="passwordConf"
									autoComplete="new-password"
									onChange={e => setConfirmPassword(e.target.value)}
								/>
								{passwordError && <label>{passwordError}</label>}

								<Button title={title} handleAction={handleAction} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</ThemeProvider>
			<div>
				<Button
					title={title}
					handleAction={() => {
						if (password !== confirmPassword) {
							setPasswordError('Senhas não são iguais');
						} else {
							setPasswordError('');
							handleAction();
						}
					}}
				/>
			</div>
		</>
	);
}
